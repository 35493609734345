import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapCluster = _resolveComponent("GMapCluster")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_GMapMap, {
      options: _ctx.mapOptions,
      center: _ctx.position,
      zoom: _ctx.zoomLevel,
      "map-type-id": "terrain",
      ref: "mapRef",
      class: "training-map-anthro"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clusters, (m, index) => {
          return (_openBlock(), _createBlock(_component_GMapCluster, {
            key: index,
            onClick: ($event: any) => (_ctx.onClick(m[0])),
            styles: [
          {
            textColor: 'white',
            url: require('@/assets/png/purple-marker.png'),
            height: 25,
            width: 25,
            textSize: m.length > 99 ? 9 : 12,
            anchorText: m.length > 99 ? [4, 10] : [3, 10],
            fontWeight: 'bold',
          },
        ]
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(m, (marker) => {
                return (_openBlock(), _createBlock(_component_GMapMarker, {
                  key: marker.id,
                  position: marker.position,
                  icon: require('@/assets/png/purple-marker.png'),
                  clickable: true,
                  onClick: ($event: any) => (_ctx.onClick(marker))
                }, null, 8, ["position", "icon", "onClick"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["onClick", "styles"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["options", "center", "zoom"])
  ]))
}