
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Map from './Map.vue';

interface markerProps {
  id: number;
  position: { lat: number | undefined; lng:number|undefined };
  location: undefined | string;
}

export default defineComponent({
  name: 'TrainingMap',
  components: {
    Map,
  },
  data() {
    return {
      mapMarkers: undefined as undefined|Array<markerProps[]>,
    };
  },
  mounted() {
    this.getMapMarkers();
  },
  computed: {
    ...mapGetters('training', ['getFilterValues', 'getLoadingState', 'getTrainings']),
  },
  methods: {
    getMapMarkers() {
      const values = this.getFilterValues;
      const m:Array<markerProps[]> = [];
      if (values && values.length > 1) {
        const latIndex = values[0].indexOf('Latitude');
        const lngIndex = values[0].indexOf('Longitude');
        const levelIndex = values[0].indexOf('Level of Event');
        const locationIndex = values[0].indexOf('Country of Training');
        const compareValues:Array<string[]> = this.getTrainings;
        compareValues.forEach((row: string[], i: number) => {
          const obj = { id: i, position: { lat: undefined as undefined|number, lng: undefined as undefined|number }, location: undefined as undefined|string };
          if (levelIndex <= row.length - 1 && row[levelIndex] === 'Virtual') {
            obj.position.lat = 43.65;
            obj.position.lng = -79.38;
            obj.location = 'Canada';
          } else if (latIndex <= row.length - 1 && lngIndex <= row.length - 1) {
            if (row[latIndex].length > 1 && !row[latIndex].includes('S')) {
              obj.position.lat = parseFloat(row[latIndex].slice(0, row[latIndex].indexOf('°')));
            } else if (row[latIndex].length > 1) {
              obj.position.lat = parseFloat(row[latIndex].slice(0, row[latIndex].indexOf('°'))) * -1;
            }
            if (row[lngIndex].length > 1 && !row[lngIndex].includes('W')) {
              obj.position.lng = parseFloat(row[lngIndex].slice(0, row[lngIndex].indexOf('°')));
            } else if (row[lngIndex].length > 1) {
              obj.position.lng = parseFloat(row[lngIndex].slice(0, row[lngIndex].indexOf('°'))) * -1;
            }
            if (locationIndex <= row.length - 1 && row[locationIndex].length > 1) {
              obj.location = row[locationIndex];
            }
          }
          // cluster same lat and lng into array
          if (obj.position.lat && obj.position.lng) {
            const clusterIndex = m.findIndex((x) => (x[0].position.lat === obj.position.lat && x[0].position.lng === obj.position.lng));
            if (clusterIndex === -1) {
              m.push([obj]);
            } else {
              m[clusterIndex].push(obj);
            }
          }
        });
      }
      let mIndex = 0;
      let markersMatched = true;
      if (m.length > 0 && this.mapMarkers && this.mapMarkers.length === m.length) {
        while (markersMatched && mIndex <= m.length - 1) {
          if (JSON.stringify(m[mIndex]) !== JSON.stringify(this.mapMarkers[mIndex])) {
            markersMatched = false;
          }
          mIndex += 1;
        }
      } else {
        markersMatched = false;
      }
      if (!markersMatched) {
        this.$nextTick(() => {
          this.mapMarkers = m;
        });
      }
    },
  },
  watch: {
    getLoadingState(newProps, oldProps) {
      if (newProps === 'done' && oldProps !== newProps) {
        this.getMapMarkers();
      }
    },
  },
});
