
/* global google */
/* eslint-disable */

import { defineComponent, PropType, watch, ref, reactive } from 'vue';
import { mapGetters } from 'vuex';
import { DEFAULT_MAP_OPTIONS } from '@/utils/map';
// control svgs
import blackPlus from '@/assets/svg/plus.svg';
import blackMinus from '@/assets/svg/minus.svg';
import caretDown from '@/assets/svg/caret-down.svg';
import caretUp from '@/assets/svg/caret-up.svg';

interface markerProps {
  id: number;
  position: { lat: number; lng:number };
  location: string;
}


export default defineComponent({
  name: 'Map',
  props: {
    clusters: {
      type: Array as PropType<Array<markerProps[]>>,
    },
  },
  setup() {
    const mapRef = ref();
    const zoomLevel = ref(3);
    const position = reactive({
        lat: -3.8333,
        lng: 29.3677,
    });
    watch(mapRef, (googleMap) => {
      if (googleMap) {
        googleMap.$mapPromise.then((map:any) => {
          // map controls
          const controlUI = document.createElement('div');
          controlUI.classList.add('training-map-controls');
          const controlPlusContainer = document.createElement('div');
          controlPlusContainer.style.width = '100%';
          const controlPlus = new Image(12);
          controlPlus.src = blackPlus;
          controlPlusContainer.appendChild(controlPlus);
          controlPlusContainer.addEventListener('click', () => {
            zoomLevel.value += 1;
          });
          const controlMinusContainer = document.createElement('div');
          controlMinusContainer.style.width = '100%';
          const controlMinus = new Image();
          controlMinus.src = blackMinus;
          controlMinusContainer.appendChild(controlMinus);
          controlMinusContainer.addEventListener('click', () => {
            zoomLevel.value -= 1;
          });
          const navIcon = document.createElement('div');
          navIcon.classList.add('training-caret-controls');
          const controlCaretDown = new Image();
          controlCaretDown.src = caretDown;
          const controlCaretUp = new Image();
          controlCaretUp.src = caretUp;
          navIcon.appendChild(controlCaretUp);
          navIcon.appendChild(document.createElement('br'));
          navIcon.appendChild(controlCaretDown);
          navIcon.addEventListener('click', () => {
            map.setCenter(position);
          });
          controlUI.appendChild(controlPlusContainer);
          const firstBr = document.createElement('br');
          controlUI.appendChild(firstBr);
          firstBr.classList.add('training-br');
          controlUI.appendChild(controlMinusContainer);
          const secondBr = document.createElement('br');
          secondBr.classList.add('training-br');
          controlUI.appendChild(secondBr);
          controlUI.appendChild(navIcon);
          //@ts-expect-error
          map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlUI);
        });
      }
    });
    return {
      mapRef,
      zoomLevel,
      position,
    };
  },
  computed: {
    ...mapGetters('training', ['getLoadingState']),
    mapOptions() {
      const mapOptions = { ...DEFAULT_MAP_OPTIONS };
      mapOptions.styles.forEach((feature) => {
        if (feature.featureType == 'administrative.country') {
          feature.stylers.forEach((style:any ) => {
            if (style?.weight) {
              style.weight = '1';
            }
          })
        }
      })
      mapOptions.draggable = true;
      mapOptions.gestureHandling = 'cooperative';
      return mapOptions;
    },
  },
  methods: {
    zoomIn() {
      this.zoomLevel += 1;
    },
    zoomOut() {
      this.zoomLevel -= 1;
    },
    onClick(marker: markerProps){
      this.position = marker.position;
      this.zoomLevel = 6;
      this.$forceUpdate();
    },
  },
  watch: {
    getLoadingState(newProps, oldProps) {
      if (newProps !== oldProps && newProps === 'done') {
        const locations = this.$store.getters['trainingFilter/getLocation'];
        if (locations) {
          const values = this.$store.getters['training/getDefaultValues'];
          let lat:number|undefined = undefined;
          let lng:number|undefined = undefined;
          if (values && values.length > 1) {
            const locationIndex = values[0].indexOf('Country of Training');
            values.findIndex((row:string[]) => {
              if (row.length >= locationIndex && row[locationIndex] === locations) {
                const latIndex = values[0].indexOf('Latitude');
                const lngIndex = values[0].indexOf('Longitude');
                if (latIndex <= row.length - 1 && lngIndex <= row.length - 1) {
                  if (row[latIndex].length > 1 && !row[latIndex].includes('S')) {
                    lat = parseFloat(row[latIndex].slice(0, row[latIndex].indexOf('°')));
                  } else if (row[latIndex].length > 1) {
                    lat = parseFloat(row[latIndex].slice(0, row[latIndex].indexOf('°'))) * -1;
                  }
                  if (row[lngIndex].length > 1 && !row[lngIndex].includes('W')) {
                    lng = parseFloat(row[lngIndex].slice(0, row[lngIndex].indexOf('°')));
                  } else if (row[lngIndex].length > 1) {
                    lng = parseFloat(row[lngIndex].slice(0, row[lngIndex].indexOf('°'))) * -1;
                  }
                }
              }
              if (lat && lng) return true;
              lat = undefined;
              lng = undefined;
            })
          }
          if (lat && lng && (this.position?.lat !== lat || this.position?.lng === lng)) {
            this.position = { lat, lng };
            this.zoomLevel = 6;
          }
        }
      };
    },
  },
});
